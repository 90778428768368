import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            author
            imageOG
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <link rel="canonical" href="https://dosugz.ru/"/>
      <link rel="alternate" href="https://dosugz.ru/" hreflang="ru"/>
      <link rel="amphtml" href="https://dosugz.ru/amp/"/>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
	    <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>
	    <link rel="icon" href="/favicon.ico" sizes="32x32"/>
      <title>{defaultTitle ? `${title} - ${defaultTitle}` : title}</title>
      <meta property="og:locale" content="ru_RU"/>
      <meta property="og:site_name" content="dosugz.ru"/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={site.siteMetadata.imageOG}/>
      <meta property="og:url" content="https://dosugz.ru/"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:image" content={site.siteMetadata.imageOG}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:site" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta itemprop="image" content={site.siteMetadata.imageOG}/>
	    <meta itemprop="description" content={metaDescription}/>
	    <meta itemprop="name" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
	    <meta name="description" content={metaDescription}/>
      <link rel="alternate" type="application/atom+xml" href="https://dosugz.ru/dosugz.atom" title="Dosug (Досуг) переход на сайт"/>
      <script type="application/ld+json">
  {`
   {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://dosugz.ru/"
    },
    "headline": "Dosug (Досуг) переход на сайт",
    "image": {
        "@type": "ImageObject",
        "url": "https://dosugz.ru/images/dosugz-og.png",
        "height": 630,
        "width": 1200
    },
    "author": {
        "url": "https://dosugz.ru/",
        "@type": "Organization",
        "name": "Dosugz.Ru"
    },
    "publisher": {
        "@type": "Organization",
        "name": "Dosugz.Ru",
        "logo": {
            "@type": "ImageObject",
            "url": "https://dosugz.ru/images/amp.png",
            "width": 312,
            "height": 60
        }
    }
}
  `}
</script>
      {children}
    </>
  )
}

export default Seo
