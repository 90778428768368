import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {
  return (
    <Layout>
     <div id="wrapper">
		<div id="main">
			<div class="inner">
				<div id="container01" class="style2 container default full">
					<div class="wrapper">
						<div class="inner">
						<div class="style1 image"><span class="frame">
                  <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Dosug"/></span></div>
							<h1 class="style3">Переход на сайт <strong>Dosug</strong></h1>
							<p id="text11">Ищите, кнопку для входа на Досуг? Всегда рабочая ссылка на элитный портал!</p>
						</div>
					</div>
				</div>
				<ul class="style1 buttons">
					<li><a href="/cgi-bin/z.pl" class="button n01">ВХОД 18+</a></li>
				</ul>
				<hr class="style1"></hr>
				<div class="style1 container default">
					<div class="wrapper">
						<div class="inner">
							<p id="text03">Просим учесть сотрудников госорганизаций, что здесь ничего не содержится, кроме розовой кнопки. Нажатием её пользователь будет переадресован на случайный адрес в сети Интернет. Надеемся, что не будет совершено никаких противоправных действий в отношении ресурса.</p>
						</div>
					</div>
				</div>
				<hr class="style1"></hr>
				<div class="style1 container default">
					<div class="wrapper">
						<div class="inner">
							<p class="style1">На портале собрана внушительная база телефонов и адресов с лучшими цыпочками Москвы, Питера и не только. Они помогут Вам развеяться, если Вам холодно и одиноко. Они сделают так, чтобы Вам было хорошо и весело. С ними Вы сможете сначала хорошо провести время, а после хорошо побеседовать. И Вы надолго запомните эти приятные ощущения и воспоминания. Потому что с этими классными девушками исполнятся все Ваши тайные желания и самые сокровенные мечты. При этом Vip раздел позволяет выбрать самую прекрасную девушку из множества возможных.</p>
							<hr class="style1"></hr>
							<p class="style1">Каждая девушка, анкета которой представлена, отличается неповторимым темпераментом и характером. Выберите наиболее подходящую именно Вам, из представленного множества девушек. При этом всё сделано для того, чтобы Вы нашли себе девушку по карману. От бюджетной до элитной. Все фотографии, размещенные в анкетах, прошли строгую проверку и соответствуют действительности. Это упрощает поиск и выбор девушек.</p>
							<hr class="style1"></hr>
							<p class="style1">Также отдельно хотелось бы упомянуть о таком разделе <em>Dosug</em> как фотогалерея, где можно очень быстро отобрать понравившихся девушек исключительно по их внешности, а уж потом быстро отсеять тех, которые по тем или иным причинам вам не подходят.</p>
						</div>
					</div>
				</div>
				<div id="container05" class="style2 container default full">
					<div class="wrapper">
						<div class="inner">
							<ul class="style1 icons">
								<li>
									<a href="https://t.me/DosugOfficialle" aria-label="Telegram">
									<svg viewBox="0 0 40 40" display="none" width="0" height="0" xmlns="http://www.w3.org/2000/svg">
                      <path d="M34,9.6l-4.9,22.9c-0.3,1.6-1.3,2-2.7,1.2L19,28.3l-3.6,3.5c-0.3,0.4-0.7,0.8-1.5,0.8l0.4-7.6l0,0l-0.1,0 l13.9-12.4c0.7-0.6-0.1-0.8-0.9-0.3L10.2,22.9l-7.4-2.3c-1.7-0.4-1.7-1.6,0.3-2.3l28.8-11C33.2,6.7,34.5,7.6,34,9.6z" fill="#FFFFFF"></path>
                    </svg><span class="label">Telegram</span></a>
								</li>
								<li>
									<a href="https://vk.com/dosug.cz_ru" aria-label="VK">
									<svg viewBox="0 0 40 40" display="none" width="0" height="0" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.7,33.8C7.7,33.8,0.3,25,0,10.2h6.5c0.2,10.9,5,15.5,8.8,16.4V10.2h6.1v9.4c3.8-0.4,7.7-4.7,9-9.4h6.1 c-1,5.8-5.3,10.1-8.3,11.8c3,1.4,7.9,5.1,9.8,11.9h-6.7c-1.4-4.5-5.1-8-9.8-8.5v8.5H20.7z" fill="#FFFFFF"></path>
                                </svg><span class="label">VK</span></a>
								</li>
								<li>
								<a href="/dosugz.atom" aria-label="Feed">
                                <svg viewBox="0 0 40 40" display="none" width="0" height="0" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.98,30.77c0,1.17-0.41,2.17-1.23,2.99C11.94,34.59,10.94,35,9.77,35c-1.17,0-2.16-0.41-2.98-1.23 c-0.82-0.82-1.23-1.82-1.23-2.99s0.41-2.17,1.23-2.99c0.82-0.82,1.81-1.23,2.98-1.23c1.17,0,2.16,0.41,2.98,1.23 C13.57,28.6,13.98,29.6,13.98,30.77z M25.21,33.48c0.03,0.41-0.1,0.76-0.37,1.06C24.57,34.85,24.23,35,23.8,35h-2.96 c-0.37,0-0.68-0.12-0.94-0.36c-0.26-0.24-0.41-0.55-0.44-0.91c-0.32-3.36-1.67-6.23-4.05-8.62c-2.38-2.39-5.24-3.74-8.58-4.06 c-0.37-0.03-0.67-0.18-0.91-0.44c-0.24-0.26-0.36-0.58-0.36-0.95v-2.97c0-0.43,0.15-0.77,0.46-1.03c0.25-0.25,0.56-0.37,0.94-0.37 h0.11c2.34,0.19,4.57,0.78,6.71,1.77c2.13,0.99,4.03,2.32,5.68,4c1.67,1.66,2.99,3.56,3.98,5.7C24.43,28.89,25.02,31.13,25.21,33.48 z M36.43,33.52c0.03,0.4-0.1,0.74-0.39,1.03c-0.26,0.29-0.6,0.44-1.01,0.44h-3.14c-0.38,0-0.71-0.13-0.98-0.39 c-0.27-0.26-0.41-0.57-0.43-0.94c-0.18-3.16-0.91-6.15-2.21-8.99c-1.3-2.84-2.99-5.31-5.08-7.4c-2.08-2.09-4.54-3.79-7.37-5.1 c-2.83-1.31-5.81-2.05-8.96-2.25C6.51,9.93,6.2,9.79,5.95,9.52C5.69,9.24,5.56,8.92,5.56,8.56V5.41C5.56,5,5.71,4.66,6,4.4 C6.26,4.13,6.59,4,6.97,4h0.07c3.83,0.19,7.49,1.07,11,2.64c3.5,1.57,6.61,3.73,9.33,6.47c2.73,2.73,4.88,5.85,6.45,9.37 C35.37,26,36.24,29.68,36.43,33.52z" fill="#FFFFFF"></path>
                                </svg><span class="label">Feed</span>
                            </a>
							</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  </Layout>
)
}
export const Head = () => <Seo title="Dosug (Досуг) переход на сайт"/>
export default IndexPage
export const query = graphql`
query MyQuery {
  file(relativePath: {eq: "logo.png"}) {
    childImageSharp {
      gatsbyImageData(
        height: 390
        width: 390
        formats: [AUTO, PNG, AVIF, WEBP]
        layout: CONSTRAINED
        quality: 100
        pngOptions: {quality: 100}
        avifOptions: {quality: 100}
        webpOptions: {quality: 100}
        breakpoints: [30,60,90,120,150,180,210,240,270,300,330,360,390]
      )
    }
  }
}
`
